.buttons{
    height: 125px;
}

.print_class{
    size: A4 landscape;    
    height: 99%;
    margin-top: 10px;
    page-break-after: avoid;
    page-break-before: avoid;
}

.last_row{
    font-size: large;
    font-weight: bolder;
}

