a{
    text-decoration: none;
}

.main_widget{
    
    height: 85px;
}

.icon{
    
    border-radius: 5px;
    
    
    font-size: 40px;
}

.widget:hover{
    box-shadow: 0px 5px 20px -7px rgba(0, 0, 0, 0.75);
}




