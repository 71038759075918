

.table-sm>:not(caption)>*>* {
    padding: 0.25rem 1rem;
}

/* .sr-only{
    visibility: hidden;
} */

.action > .MuiIconButton-root {

    flex: 0 0 auto;
    color: rgba(0, 0, 0, 0.54);
    padding: 0;
    overflow: visible;
    font-size: 1.5rem;
    transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 50%;
}

.customClass{
    height: 5rem;
}

.csvbutton{
    height: fit-content;
}

.user_main>h1{
    margin-bottom: 2%;
}

.user_main{
    display: flex;
    flex-direction: column;
    margin-left: 1%;
    margin-bottom: 2%;
}

.button{
    width: 15%;
    
}

.react-bootstrap-table table {
    table-layout: auto;
  }

thead {
    border-bottom-width: 3px;
}

.react-bootstrap-table table{
    border: 1px solid #dee2e6;;
    border-bottom-width: 2px;
    border-top-width: 2px;
}

.select{
    width: fit-content;
    
}

td {
    vertical-align: middle;
  }

.low_quantity{
    
    background-color:rgb(228, 98, 98);
    font-weight: bolder;
}

.selector{
    border:none;
    padding: 0;
}

.upper-div:hover{
    box-shadow:0px 5px 20px -5px rgba(0, 0, 0, 0.75);
    transition: box-shadow 0.8s ease;
    cursor: pointer;
}

.position{
    position: -webkit-sticky;
    position: sticky;
    top: 0;

}

.img-list:hover{
    box-shadow:0px 5px 20px -5px rgba(0, 0, 0, 0.75);
    border: 1px solid white;
    cursor: pointer;
}

@media only screen and (max-width: 768px) {
    .image{
        width:230px;
        height: 350px;
    }
    
}



