/* .table_class > thead{
    border: 1px solid black !important;
}
.table_class > tbody{
    border: 1px solid black !important;
} */


.tabel-class{
    border: 1px solid white !important;
    
}

.tabel-class>thead{
    border: 1px solid white !important;
    
}



.tabel-class >thead>tr> th:first-child{
    background-color: #661b1c;
    color: white;
    margin-right: 2px;
}

.tabel-class >thead>tr> th:nth-child(2){
    background-color: #661b1c;
    color: white;
    margin-right: 2px;
}

.tabel-class >thead>tr> th:nth-child(3){
    background-color: #000;
    color: white;
}
.tabel-class >thead>tr> th:nth-child(4){
    background-color: #000;
    color: white;
}
.tabel-class >thead>tr> th:last-child{
    background-color: #000;
    color: white;
}

.printclass{
        height: 100%;
        margin-top: 0;
        page-break-after: avoid;
        page-break-before: avoid;
}

.mydiv{
    width: 5.75in;
    height: 9in;

}

@media  print {
    
    @page {
        size: 5.75in 9in;
      }
}
.header_0{
    clip-path: polygon(0 0, 100% 0 ,85% 100%,0 100%);
}
.header_1{
    clip-path:polygon(0 0, 95% 0 ,86% 100%,0 100%);
}
.header_2{
    clip-path:polygon(0 0, 97% 0 ,85% 100%,0 100%);
}

.signature{
    vertical-align: bottom !important;
}