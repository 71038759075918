



.custom_row_class >td {
    padding: 4px 0 4px 16px !important;
}

.user_main{
    display: flex;
    flex-direction: column;
    margin-left:2%;
    margin-right:2%;
    margin-bottom: 2%;

}

.form-control:focus{
    border-color:slategray;
    border-width: 2px;
}

.input{
    border: 0;
    font-size:x-large;
}

.input :focus{
outline: none;
}

.table_class:hover{
    cursor: pointer;   
}






.saleman::after{
    content: 'City';
    position: absolute;
    top: -9px;
    left: 9px;
    background-color: white;
    padding: 0 5px;
    color: gray;
    font-size: 12px;
    }


.mydropdown > button{
    padding: 0;
}

.mydropdown{
    position: absolute !important;   
}

@media only screen and (max-width:768px){
    .mydropdown{
        position: relative !important; 

    }
}

.history-table > tbody >tr >td{
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
}
